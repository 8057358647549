import type { SearchResultItem } from '../../../atoms/searchResults.js'

import { SearchCategory } from './SearchCategory.js'
import { SearchStory } from './SearchStory.js'

type Props = {
  item: SearchResultItem
  onClose: () => void
  navigationDisabled?: boolean
  activeItemId?: string
}

export function SearchResult(props: Props) {
  const { item, onClose, navigationDisabled, activeItemId } = props

  switch (item.type) {
    case 'node':
      return <SearchCategory item={item} />
    case 'item':
      return (
        <SearchStory
          item={item}
          onCloseSearchDialog={onClose}
          navigationDisabled={navigationDisabled}
          active={activeItemId === item.id}
        />
      )
  }
}
