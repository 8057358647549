import { useCallback, useEffect, useMemo, useRef } from 'react'
import { type NodeApi, Tree, type TreeApi } from 'react-arborist'
import type { TreeItem } from '../../helpers/generated.js'
import { findBySlugWithPath, getStoriesTree } from '../../helpers/parsedTree.js'
import { setTreeApi } from '../atoms/treeState.js'
import { useSelectedNodes } from '../hooks/useSelectedNodes.js'
import { useStoryRoute } from '../hooks/useStoryRoute.js'
import { NavigationTreeNode } from './NavigationTreeNode/NavigationTreeNode.js'

type Props = {
  height: number
}

export function NavigationTree(props: Props) {
  const { height: treeHeight } = props
  const { story, filter } = useStoryRoute()

  const treeApi = useRef<TreeApi<TreeItem> | undefined>(undefined)
  useEffect(() => setTreeApi(treeApi.current), [])

  const stories = useMemo<TreeItem[]>(() => {
    if (filter) {
      const foundNode = findBySlugWithPath(filter)

      if (foundNode) {
        const { item, path } = foundNode

        // overwrite label w/ folder path
        const title = path.map(x => x.title).join('/')
        const temporaryTreeRoot: TreeItem = { ...item, title }

        return [temporaryTreeRoot]
      }
    }

    return Object.values(getStoriesTree())
  }, [filter])

  const { activeStory, onSelectStory } = useSelectedNodes()

  const handleOnSelect = useCallback(
    (nodes: NodeApi<TreeItem>[]) => {
      const node = nodes[0]
      if (!node) return

      if (node.isLeaf) {
        onSelectStory(node.data.slug)
      }
    },
    [onSelectStory],
  )

  return (
    <Tree
      ref={treeApi}
      data={stories}
      selection={activeStory}
      onSelect={handleOnSelect}
      disableMultiSelection
      disableDrag
      disableDrop
      disableEdit
      width="100%"
      height={treeHeight}
      rowHeight={34}
      indent={12}
      paddingBottom={100}
      idAccessor="slug"
      openByDefault={false}
    >
      {NavigationTreeNode}
    </Tree>
  )
}
