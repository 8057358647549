import { Box, type SxProps, type Theme } from '@mui/material'
import { useElementSize } from '@workwave-tidal/core/hooks'
import { type ReactNode, forwardRef, useCallback, useLayoutEffect, useRef } from 'react'

type Props = {
  sx?: SxProps<Theme>
  children: ReactNode
  onChangeHeight?: (height: number) => void
}
export const ScrollableContainer = forwardRef<HTMLDivElement, Props>(function ScrollableContainer(props, ref) {
  const { sx = {}, children, onChangeHeight } = props

  const localRef = useRef<HTMLElement | null>(null)
  const { height } = useElementSize(localRef.current)

  const mergeRef = useCallback(
    (instance: HTMLDivElement | null) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref) {
        ref.current = instance
      }

      localRef.current = instance
    },
    [ref],
  )

  useLayoutEffect(() => {
    onChangeHeight?.(height)
  }, [onChangeHeight, height])

  return (
    <Box
      sx={{
        flex: '1 0 auto',
        position: 'relative',
        ...sx,
      }}
    >
      <Box
        ref={mergeRef}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
})
