import { Brightness4, Brightness7 } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, useColorScheme } from '@mui/material'
import { useCallback } from 'react'

export function DarkModeToggler({ sidebarOpen }: { sidebarOpen: boolean }) {
  const { mode, setMode } = useColorScheme()

  const onToggleMode = useCallback(() => {
    setMode(mode === 'dark' ? 'light' : 'dark')
  }, [mode, setMode])

  const text = mode === 'dark' ? 'Enable light mode' : 'Enable dark mode'

  return (
    <Tooltip placement="right" arrow title={sidebarOpen ? '' : text}>
      <ListItem
        data-testid="dark-mode-toggler"
        disablePadding
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: 0,
        }}
        onClick={onToggleMode}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sidebarOpen ? 'initial' : 'center',
            px: 2.5,
            padding: 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
              px: 1,
            }}
          >
            {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: sidebarOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}
