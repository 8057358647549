import { type DragHandlers, type MotionValue, motion } from 'framer-motion'
import { useCallback, useLayoutEffect, useRef } from 'react'

import { Height as HeightIcon } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'

import type { UpdateFunction } from '@workwave-tidal/core/hooks'

export type Props = {
  sidebarPosition: 'row' | 'row-reverse'
  mWidth: MotionValue<number>
  setNavigationWidth: UpdateFunction<number>
}

export function ResizeNavigation(props: Props) {
  const { sidebarPosition, mWidth, setNavigationWidth } = props

  const rSidebarPosition = useRef(sidebarPosition)
  useLayoutEffect(() => void (rSidebarPosition.current = sidebarPosition), [sidebarPosition])

  const handleOnResize = useCallback<Required<DragHandlers>['onDrag']>(
    (_event, info) => {
      const newWidth =
        rSidebarPosition.current === 'row-reverse' ? mWidth.get() - info.delta.x : mWidth.get() + info.delta.x

      if (newWidth > 250 && newWidth < 800) {
        mWidth.set(newWidth)
      }
    },
    [mWidth],
  )

  const handleOnResizeEnd = useCallback<Required<DragHandlers>['onDragEnd']>(
    (_event, _info) => {
      setNavigationWidth(mWidth.get())
    },
    [setNavigationWidth, mWidth],
  )

  const handleResetWidth = useCallback(() => {
    setNavigationWidth(350)
    mWidth.set(350)
  }, [setNavigationWidth, mWidth])

  return (
    <motion.div
      drag="x"
      onDrag={handleOnResize}
      onDragEnd={handleOnResizeEnd}
      dragElastic={0}
      dragMomentum={false}
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      style={{
        top: '3px',
        bottom: '3px',
        left: sidebarPosition === 'row-reverse' ? -2 : undefined,
        right: sidebarPosition === 'row' ? -2 : undefined,
        width: '4px',
        cursor: 'col-resize',
        zIndex: 90,
        position: 'absolute',
        backgroundColor: 'rgb(230, 230, 230, 0)',
      }}
      whileHover={{
        transition: { duration: 0.1 },
        backgroundColor: 'rgb(230, 230, 230, 1)',
      }}
      whileDrag={{
        transition: { duration: 0.1 },
        backgroundColor: 'rgb(230, 230, 230, 1)',
      }}
    >
      <Tooltip title="Drag to resize - Double click to reset" enterNextDelay={400} arrow>
        <Box
          sx={{
            top: 'calc(50% - 10px)',
            left: sidebarPosition === 'row' ? 'calc(100% - 13px)' : undefined,
            right: sidebarPosition === 'row-reverse' ? 'calc(100% - 13px)' : undefined,
            display: 'flex',
            padding: '2px',
            position: 'absolute',
            borderRadius: '12px',
            backgroundColor: 'rgb(230, 230, 230, 1)',
          }}
          onDoubleClick={handleResetWidth}
        >
          <HeightIcon fontSize="small" sx={{ transform: 'rotate(90deg)' }} />
        </Box>
      </Tooltip>
    </motion.div>
  )
}
