import { Box, type BoxProps, styled } from '@mui/material'
import type { ComponentType } from 'react'

export const NavigationTreeNodeLayout = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  p: 0.5,
  pr: 0,
  mr: 1,
  minHeight: 34,
  gap: 1,
})) as ComponentType<BoxProps>
