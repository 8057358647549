import { useLocation, useRoute } from '@workwave-tidal/core/router'

export function useStoryRoute() {
  const [, matches] = useRoute('/:filter*/:story')

  const [location] = useLocation()

  // ignore query params
  const maybeStory = matches?.story.replace(/[\\?].*/, '') ?? ''
  const filter = matches?.filter ?? ''

  // if url ends with / we don't have an active story
  if (location.endsWith('/')) {
    const actualFilter = filter === '' ? maybeStory : `${filter}/${maybeStory}`

    return {
      story: '',
      filter: actualFilter,
    }
  }

  return {
    story: maybeStory,
    filter,
  }
}
