import { ChevronLeft, ChevronRight, Inbox, Percent } from '@mui/icons-material'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  darken,
} from '@mui/material'
import { ThemePreview } from '@workwave-tidal/core/devtools'
import { DarkModeToggler } from './components/DarkModeToggler.js'
import { Drawer } from './components/Drawer.js'
import { WarnTooltip } from './components/WarnTooltip.js'
import { useThemeSidebar } from './hooks/useThemeSidebar.js'

const EmptyBox = () => <Box sx={{ width: 0 }} />

export function ThemeSidebar() {
  const {
    themeModified,
    onResetTheme,
    //
    onToggleSidebar,
    sidebarOpen,
    //
    onClickCoverage,
  } = useThemeSidebar()

  return (
    <Drawer variant="permanent" open={sidebarOpen} data-id="theme-sidebar-drawer">
      <Box data-testid="vertical-spacer" sx={{ flex: '1 1 auto' }} />

      {/*
        ------------------------------------------------------
        THEME EDITOR
        ------------------------------------------------------
      */}
      {sidebarOpen ? <ThemePreview onClose={onToggleSidebar} /> : <EmptyBox />}

      <Divider data-testid="divider" />

      <List data-testid="actions" sx={{ padding: 0 }}>
        {/*
          ------------------------------------------------------
          RESET MODIFIED THEME BUTTON
          ------------------------------------------------------
        */}

        <DarkModeToggler sidebarOpen={sidebarOpen} />
        {themeModified && (
          <>
            <WarnTooltip title={sidebarOpen ? '' : 'Reset Active Theme'} placement="right" arrow>
              <ListItem
                disablePadding
                data-testid="reset-theme"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 0,
                }}
                onClick={onResetTheme}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: sidebarOpen ? 'initial' : 'center',
                    padding: 0,
                    backgroundColor: th => th.vars.palette.error.dark,
                    '&:hover': th => ({
                      backgroundColor: darken(th.palette.error.dark, 0.3),
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                      px: 1,
                      color: th => th.vars.palette.tidalColors.cloudGray[50],
                    }}
                  >
                    <Inbox />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: { color: th => th.vars.palette.tidalColors.cloudGray[50] },
                    }}
                    primary="Reset Active Theme"
                    sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </WarnTooltip>

            <Divider data-testid="divider" />
          </>
        )}

        {/*
          ------------------------------------------------------
          Tidal Coverage
          ------------------------------------------------------
        */}

        <Tooltip title={sidebarOpen ? '' : 'Coverage'} placement="right" arrow>
          <ListItem
            data-testid="toggle-coverage"
            disablePadding
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 0,
            }}
            onClick={onClickCoverage}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebarOpen ? 'initial' : 'center',
                px: sidebarOpen ? 0 : 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: sidebarOpen ? 'flex-end' : 'center',
                  px: 1,
                  width: '100%',
                }}
              >
                <Percent />
              </ListItemIcon>
              <ListItemText sx={{ opacity: sidebarOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>

        {/*
          ------------------------------------------------------
          TOGGLE SIDEBAR BUTTON
          ------------------------------------------------------
        */}

        <Tooltip title={sidebarOpen ? '' : 'Open Editor'} placement="right" arrow>
          <ListItem
            data-testid="toggle-sidebar"
            disablePadding
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 0,
            }}
            onClick={onToggleSidebar}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebarOpen ? 'initial' : 'center',
                px: sidebarOpen ? 0 : 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: sidebarOpen ? 'flex-end' : 'center',
                  px: 1,
                  width: '100%',
                }}
              >
                {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
              </ListItemIcon>
              <ListItemText sx={{ opacity: sidebarOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </List>
    </Drawer>
  )
}
