import { usePreviewTheme } from '@workwave-tidal/core/devtools'
import { useToggle } from '@workwave-tidal/core/hooks'
import { telemetryEngine } from '@workwave-tidal/core/telemetry/engine'
import { useCallback } from 'react'

export function useThemeSidebar() {
  const [sidebarOpen, onToggleSidebar] = useToggle(false)

  const {
    pristine,
    api: { setTheme },
  } = usePreviewTheme()

  const themeModified = !pristine

  const onResetTheme = useCallback(() => {
    setTheme({})
  }, [setTheme])

  const onClickCoverage = useCallback(() => {
    telemetryEngine.postMessageHelpers.toggleUI()
  }, [])

  return {
    themeModified,
    onResetTheme,
    //
    onToggleSidebar,
    sidebarOpen,
    //
    onClickCoverage,
  }
}
