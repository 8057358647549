import { useCallback } from 'react'
import { storyExists } from '../../helpers/parsedTree.js'
import { useChangeStory } from './useChangeStory.js'
import { useStoryRoute } from './useStoryRoute.js'

export function useSelectedNodes() {
  const { story: activeStory } = useStoryRoute()
  const setActiveStory = useChangeStory()

  const onSelectStory = useCallback(
    (slug: string) => {
      if (storyExists(slug)) setActiveStory(slug)
    },
    [setActiveStory],
  )

  return { activeStory, onSelectStory } as const
}
