import { useLocation, useRoute } from '@workwave-tidal/core/router'
import type { SetLocation } from '@workwave-tidal/core/router/useLocation'
import { proxy, snapshot, subscribe, useSnapshot } from '@workwave-tidal/core/valtio'

import type { GlobalControlsConfig, LazyResolver, TidalStories, TidalStoriesApi } from '@workwave-tidal/stories-helpers'
import { useCallback, useEffect } from 'react'

let setRouterLocation: SetLocation | undefined

type UrlParams = 'nokeybindings' | 'fs' | 'collapsed' | 'disabled' | 'width' | 'direction' | 'nopanels' | ''

const urlParamsToField = new Map<UrlParams, keyof TidalStories>([
  ['nokeybindings', 'disableKeybindings'],
  ['fs', 'fullScreen'],
  ['', 'globalControlsConfig'],
  ['nopanels', 'globalControlsDisabled'],
  ['collapsed', 'navigationCollapsed'],
  ['disabled', 'navigationDisabled'],
  ['width', 'navigationWidth'],
  ['direction', 'sidebarPosition'],
])
const fieldToUrlParams = new Map<keyof TidalStories, UrlParams>([
  ['disableKeybindings', 'nokeybindings'],
  ['fullScreen', 'fs'],
  ['globalControlsConfig', ''],
  ['globalControlsDisabled', 'nopanels'],
  ['navigationCollapsed', 'collapsed'],
  ['navigationDisabled', 'disabled'],
  ['navigationWidth', 'width'],
  ['sidebarPosition', 'direction'],
])

const defaultFieldsValues: TidalStories = {
  navigationDisabled: false,
  disableKeybindings: false,
  fullScreen: false,
  globalControlsConfig: {},
  globalControlsDisabled: false,
  navigationCollapsed: false,
  navigationWidth: 350,
  sidebarPosition: 'row-reverse',
}

// ------------------------------------------------------
// ------------------------------------------------------

const storiesStore = proxy<TidalStories>(defaultFieldsValues)

// ------------------------------------------------------
// ------------------------------------------------------

function updateStateFromURL() {
  const href = window.location.href

  const urlParams = href
    .slice(href.indexOf('?') + 1)
    .split('&')
    .reduce<Partial<Record<keyof TidalStories, string>>>((acc, param) => {
      const [key, val] = param.split('=')
      const field = urlParamsToField.get(key as UrlParams)
      if (field !== undefined) {
        acc[field] = val
      }
      return acc
    }, {})

  for (const key of Object.keys(defaultFieldsValues)) {
    const field = key as keyof TidalStories

    const urlValue = urlParams[field]
    const useUrlValue = urlValue !== undefined

    switch (field) {
      case 'navigationDisabled':
      case 'disableKeybindings':
      case 'fullScreen':
      case 'navigationCollapsed':
      case 'globalControlsDisabled':
        storiesStore[field] = useUrlValue ? urlValue === 'true' : defaultFieldsValues[field]
        break

      case 'navigationWidth':
        storiesStore[field] = useUrlValue ? Number.parseInt(urlValue, 10) : defaultFieldsValues[field]
        break

      case 'sidebarPosition':
        storiesStore[field] = useUrlValue
          ? urlValue === 'row-reverse'
            ? 'row-reverse'
            : 'row'
          : defaultFieldsValues[field]
        break

      case 'globalControlsConfig':
      default:
        continue
    }
  }
}

updateStateFromURL()

function updateURLState() {
  let nextParams = ''

  for (const [key, value] of Object.entries(storiesStore)) {
    let urlParamValue = ''

    const field = key as keyof TidalStories

    switch (field) {
      case 'navigationDisabled':
      case 'disableKeybindings':
      case 'fullScreen':
      case 'navigationCollapsed':
      case 'globalControlsDisabled':
        urlParamValue = value ? 'true' : 'false'
        break

      case 'navigationWidth':
        urlParamValue = Math.round(value as TidalStories[typeof field]).toString()
        break

      case 'sidebarPosition':
        urlParamValue = value as TidalStories[typeof field]
        break

      case 'globalControlsConfig':
      default:
        continue
    }

    // skip default values
    if (defaultFieldsValues[field] === value) continue

    const urlParamKey = fieldToUrlParams.get(key as keyof TidalStories)
    nextParams = `${nextParams}&${urlParamKey}=${urlParamValue}`
  }

  // remove initial `&` character
  nextParams = nextParams.slice(1)
  if (nextParams.length > 0) nextParams = `?${nextParams}`

  let routerLocation = window.location.hash
  if (routerLocation.startsWith('#')) routerLocation = routerLocation.slice(1)

  const queryParamsIndex = routerLocation.indexOf('?')
  const rootLocation = queryParamsIndex !== -1 ? routerLocation.slice(0, queryParamsIndex) : routerLocation

  const nextLocation = `${rootLocation}${nextParams}`

  // use `replace` to avoid creating extra navigation states
  setRouterLocation?.(nextLocation, { replace: true })
}

subscribe(storiesStore, () => {
  updateURLState()
})

// ------------------------------------------------------
// ------------------------------------------------------

export function getTidalStoriesValue<Field extends keyof TidalStories>(field: Field) {
  return storiesStore[field]
}

export function setTidalStoriesValue<
  Field extends keyof TidalStories,
  Value extends TidalStories[Field] = TidalStories[Field],
>(field: Field, value: Value) {
  storiesStore[field] = value
}

export function updateGlobalControlsConfig(
  configOrSetFunction: GlobalControlsConfig | LazyResolver<GlobalControlsConfig>,
) {
  const field = storiesStore.globalControlsConfig

  const config =
    typeof configOrSetFunction === 'function'
      ? configOrSetFunction(snapshot(storiesStore.globalControlsConfig))
      : configOrSetFunction

  for (const key of Object.keys(config)) {
    field[key as keyof typeof field] = {
      ...field[key as keyof typeof field],
      ...config[key as keyof typeof config],
    }
  }
}

export function setGlobalControlsConfig(
  configOrSetFunction: GlobalControlsConfig | LazyResolver<GlobalControlsConfig>,
) {
  const config =
    typeof configOrSetFunction === 'function'
      ? configOrSetFunction(snapshot(storiesStore.globalControlsConfig))
      : configOrSetFunction

  for (const key of Object.keys(storiesStore.globalControlsConfig)) {
    delete storiesStore.globalControlsConfig[key as keyof TidalStoriesApi['setGlobalControlsConfig']]
  }

  Object.assign(storiesStore.globalControlsConfig, config)
}

// ------------------------------------------------------
// ------------------------------------------------------

export function useSyncRouterToStoriesState() {
  const [, setLocation] = useLocation()
  const [, matches] = useRoute('/:all*')

  // biome-ignore lint/correctness/useExhaustiveDependencies: The effect must trigger every time `matches?.all` changes.
  useEffect(() => {
    updateStateFromURL()
  }, [matches?.all])

  setRouterLocation = setLocation
}

export function useTidalStoriesState() {
  return useSnapshot(storiesStore)
}

export function useSetTidalStoriesStateField<Field extends keyof TidalStories>(field: Field) {
  return useCallback(
    (valueOrSetFunction: TidalStories[Field] | ((prev: TidalStories[Field]) => TidalStories[Field])) => {
      setTidalStoriesValue(
        field,
        typeof valueOrSetFunction === 'function' ? valueOrSetFunction(getTidalStoriesValue(field)) : valueOrSetFunction,
      )
    },
    [field],
  )
}

export function useTidalStoriesStateField<Field extends keyof TidalStories>(
  field: Field,
): [value: TidalStories[Field], ReturnType<typeof useSetTidalStoriesStateField<Field>>] {
  const value = useSnapshot(storiesStore)[field]

  return [value, useSetTidalStoriesStateField<Field>(field)]
}
