import { Box, type BoxProps, alpha, styled } from '@mui/material'
import type { ComponentType } from 'react'

export interface FormOverviewTreeNodeRootProps extends BoxProps {
  isSelected?: boolean
  isLeaf?: boolean
  disabled?: boolean
}

const doNotForward = new Set(['isSelected', 'isLeaf', 'disabled'])

export const NavigationTreeNodeRoot = styled(Box, {
  shouldForwardProp: (prop: string) => !doNotForward.has(prop),
})<FormOverviewTreeNodeRootProps>(({ theme, isSelected, isLeaf, disabled }) => ({
  ...theme.typography.body1,

  width: '100%',
  minHeight: 34,
  cursor: 'pointer',
  backgroundColor: isSelected ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : 'transparent',
  color: isLeaf ? theme.vars.palette.primary.main : theme.vars.palette.text.primary,

  '&:hover': {
    backgroundColor: isSelected
      ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + 0.1)
      : alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
  },

  ...(disabled && {
    pointerEvents: 'none',
    opacity: theme.vars.palette.action.disabledOpacity,
  }),
})) as ComponentType<FormOverviewTreeNodeRootProps>
