import {
  BuildCircle as BuildCircleIcon,
  Close as CloseIcon,
  History as HistoryIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon,
} from '@mui/icons-material'
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, listItemClasses } from '@mui/material'
import { useCallback, useEffect, useRef } from 'react'

import {
  type SearchResultItemStory,
  addToFavorites,
  addToRecent,
  removeFromFavorites,
  removeFromRecent,
} from '../../../atoms/searchResults.js'
import { useChangeStory } from '../../../hooks/useChangeStory.js'

export type SearchStoryProps = {
  item: SearchResultItemStory
  variant?: 'recent' | 'favorite'
  onCloseSearchDialog: () => void
  navigationDisabled?: boolean
  active?: boolean
}

export function SearchStory(props: SearchStoryProps) {
  const { item, onCloseSearchDialog, variant, navigationDisabled, active } = props

  const itemRef = useRef<HTMLDivElement | null>(null)

  const setActiveStory = useChangeStory()

  useEffect(() => {
    if (active) {
      const item = itemRef.current
      if (!item) return

      item.focus()
      item.scrollIntoView({ block: 'nearest' })
    }
  }, [active])

  const onClick = useCallback(() => {
    onCloseSearchDialog()
    if (variant !== 'favorite') {
      addToRecent(item)
    }
    setActiveStory(item.id)
  }, [item, onCloseSearchDialog, setActiveStory, variant])

  const onClickFav = useCallback(() => {
    addToFavorites(item)
  }, [item])

  const onClickRemove = useCallback(() => {
    removeFromRecent(item)
    removeFromFavorites(item)
  }, [item])

  const showFav = variant === 'recent'
  const hasVariant = !!variant
  const secondaryLabel = hasVariant ? item.path : undefined

  const icon =
    variant === 'recent' ? (
      <HistoryIcon color="primary" />
    ) : variant === 'favorite' ? (
      <StarIcon color="primary" />
    ) : (
      <BuildCircleIcon color="primary" />
    )

  return (
    <ListItem disablePadding disableGutters={hasVariant}>
      <ListItemButton
        ref={itemRef}
        className={active ? listItemClasses.focusVisible : undefined}
        onClick={onClick}
        disabled={navigationDisabled}
      >
        <ListItemIcon sx={{ pr: 1.5, minWidth: 'unset' }}>{icon}</ListItemIcon>
        <ListItemText primary={item.label} secondary={secondaryLabel} />
      </ListItemButton>

      {showFav && (
        <IconButton onClick={onClickFav}>
          <StarBorderIcon />
        </IconButton>
      )}

      {hasVariant && (
        <IconButton onClick={onClickRemove}>
          <CloseIcon />
        </IconButton>
      )}
    </ListItem>
  )
}
