import type { NodeRendererProps } from 'react-arborist'
import type { TreeItem } from '../../../helpers/generated.js'
import { useTidalStoriesStateField } from '../../atoms/storiesState.js'
import { NavigationNodeLabel } from './NavigationNodeLabel.js'
import { NavigationTreeNodeArrowIcon } from './NavigationTreeNodeArrowIcon.js'
import { NavigationTreeNodeLayout } from './NavigationTreeNodeLayout.js'
import { NavigationTreeNodeRoot } from './NavigationTreeNodeRoot.js'

export function NavigationTreeNode({ node, style }: NodeRendererProps<TreeItem>) {
  const [navigationDisabled] = useTidalStoriesStateField('navigationDisabled')

  const isLeaf = node.isLeaf
  const isSelected = node.isSelected
  const isDisabled = isLeaf && navigationDisabled

  return (
    <NavigationTreeNodeRoot
      style={style}
      isLeaf={isLeaf}
      isSelected={isSelected}
      disabled={isDisabled}
      onPointerDown={evt => {
        if (node.isInternal && !isLeaf) {
          node.toggle()
          evt.stopPropagation()
        }
        if (isLeaf) {
          node.handleClick(evt)
          node.select()
          node.activate()
        }
      }}
      onClick={evt => {
        if (!isLeaf) {
          // prevent the internal nodes to be selected. Only the leaf nodes should be selectable.
          evt.stopPropagation()
        }
      }}
    >
      <NavigationTreeNodeLayout>
        <NavigationTreeNodeArrowIcon isLeaf={isLeaf} isOpen={node.isOpen} />
        <NavigationNodeLabel item={node.data} isLeaf={isLeaf} />
      </NavigationTreeNodeLayout>
    </NavigationTreeNodeRoot>
  )
}
