import { proxy, ref, useSnapshot } from '@workwave-tidal/core/valtio'
import type { TreeApi } from 'react-arborist'
import type { TreeItem } from '../../helpers/generated.js'

const localStorageId = 'tidal-stories/tree-state'

type TreeStateAtom = {
  version: number
  treeApi: TreeApi<TreeItem> | undefined
}

export const treeStateStore = proxy<TreeStateAtom>({
  version: 2,
  treeApi: undefined,
})

export function setTreeApi(value: TreeApi<TreeItem> | undefined) {
  treeStateStore.treeApi = value ? ref(value) : undefined
}

export function collapseAll() {
  treeStateStore.treeApi?.closeAll()
}

export function expandAll() {
  treeStateStore.treeApi?.openAll()
}

export function expandToSlug(slug?: string) {
  if (slug) {
    treeStateStore.treeApi?.focus(slug)
  }
}

export function useTreeApi() {
  return useSnapshot(treeStateStore).treeApi
}
