import { createSnackMachine } from '@workwave-tidal/core/components/SnackMachine'

const snackMachine = createSnackMachine({
  config: {
    limit: 4,
    meta: { visible: 4 },
    limitChangedBehavior: 'deleteOldest',
    limitExceededBehavior: 'deleteOldest',
  },
  entries: [],
})

export function useSnackMachine() {
  return snackMachine
}
