import {
  DoubleArrow as DoubleArrowIcon,
  Fullscreen as FullscreenIcon,
  FormatTextdirectionLToR as LToRIcon,
  MenuOpen as MenuOpenIcon,
  FormatTextdirectionRToL as RToLIcon,
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material'
import { Button, Stack, Tooltip } from '@mui/material'
import type { UpdateFunction } from '@workwave-tidal/core/hooks/useQueryState'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { collapseAll, expandAll, expandToSlug, useTreeApi } from '../atoms/treeState.js'
import { useStoryRoute } from '../hooks/useStoryRoute.js'
export type Props = {
  sidebarPosition: 'row' | 'row-reverse'
  setCollapsed: UpdateFunction<boolean>
  setFullScreen: UpdateFunction<boolean>
  onChangeSidebarPosition: UpdateFunction<'row' | 'row-reverse'>
}

export function NavigationControls(props: Props) {
  const { setCollapsed, setFullScreen, sidebarPosition, onChangeSidebarPosition } = props

  const treeApi = useTreeApi()

  const rSidebarPosition = useRef(sidebarPosition)
  useLayoutEffect(() => void (rSidebarPosition.current = sidebarPosition), [sidebarPosition])

  const handleSidebarPosition = useCallback(() => {
    onChangeSidebarPosition(rSidebarPosition.current === 'row' ? 'row-reverse' : 'row')
  }, [onChangeSidebarPosition])

  const handleEnterFullScreen = useCallback(() => {
    setFullScreen(true)
  }, [setFullScreen])

  const handleCollapseSidebar = useCallback(() => {
    setCollapsed(true)
  }, [setCollapsed])

  const { story } = useStoryRoute()
  const onClickGoToStory = useCallback(() => {
    expandToSlug(story)
  }, [story])

  return (
    <Stack
      sx={{
        mb: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 0,
      }}
      direction={sidebarPosition}
    >
      <Tooltip title="Collapse sidebar">
        <Button onClick={handleCollapseSidebar}>
          <DoubleArrowIcon
            sx={{
              transform: sidebarPosition === 'row' ? 'scale(-1, 1)' : 'scale(1, 1)',
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip title="Toggle full screen">
        <Button onClick={handleEnterFullScreen}>
          <FullscreenIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Collapse">
        <Button onClick={collapseAll}>
          <UnfoldLessIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Expand">
        <Button onClick={expandAll}>
          <UnfoldMoreIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Go to current story">
        <Button onClick={onClickGoToStory}>
          <MenuOpenIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Change sidebar position">
        <Button onClick={handleSidebarPosition}>{sidebarPosition === 'row' ? <LToRIcon /> : <RToLIcon />}</Button>
      </Tooltip>
    </Stack>
  )
}
