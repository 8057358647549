import {
  ArrowUpward as ArrowUpwardIcon,
  FilterList as FilterListIcon,
  FilterListOff as FilterListOffIcon,
} from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { type MouseEvent, useCallback } from 'react'
import type { TreeItem } from '../../../helpers/generated.js'
import { expandToSlug, useTreeApi } from '../../atoms/treeState.js'
import { useChangeFilter } from '../../hooks/useChangeFilter.js'
import { useStoryRoute } from '../../hooks/useStoryRoute.js'

export type NodeProps = {
  item: TreeItem
  isLeaf?: boolean
}

export function NavigationNodeLabel(props: NodeProps) {
  const {
    isLeaf,
    item: { title, slug },
  } = props

  const treeApi = useTreeApi()
  const { story, filter } = useStoryRoute()
  const changeFilter = useChangeFilter()

  const isActive = filter === slug
  const btnDisplay = isActive ? 'flex' : 'none'
  const filterIcon = isActive ? <FilterListOffIcon fontSize="small" /> : <FilterListIcon fontSize="small" />

  const onClickFilter = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()

      if (isActive) {
        changeFilter('')
        treeApi?.focus(story)
      } else {
        changeFilter(slug)
        expandToSlug(slug)

        treeApi?.open(slug)
        treeApi?.focus(story)
      }
    },
    [story, changeFilter, isActive, slug, treeApi],
  )

  const onClickUp = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()

      const nextFilter = slug.split('/').slice(0, -1).join('/')
      changeFilter(nextFilter)
      expandToSlug(slug)

      treeApi?.open(nextFilter)
      treeApi?.focus(story)
    },
    [story, changeFilter, slug, treeApi],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        ...(!isActive && {
          ':hover > [data-id="tree-actions"]': {
            display: 'inline-flex',
          },
        }),
      }}
    >
      <Typography sx={{ flexGrow: 1, py: 0.5 }}>{title}</Typography>

      {isLeaf ? null : (
        <Box data-id="tree-actions" sx={{ display: btnDisplay }}>
          {isActive && (
            <Button sx={{ minWidth: 'unset' }} size="small" onClick={onClickUp}>
              <ArrowUpwardIcon fontSize="small" />
            </Button>
          )}
          <Button sx={{ minWidth: 'unset' }} size="small" onClick={onClickFilter}>
            {filterIcon}
          </Button>
        </Box>
      )}
    </Box>
  )
}
