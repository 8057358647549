import { BuildCircle, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'

export interface FormOverviewTreeNodeArrowProps {
  isOpen?: boolean
  isLeaf?: boolean
}

export function NavigationTreeNodeArrowIcon(props: FormOverviewTreeNodeArrowProps) {
  const { isLeaf, isOpen } = props

  return isLeaf ? (
    <BuildCircle fontSize="small" sx={{ mr: 1 }} />
  ) : isOpen ? (
    <KeyboardArrowDown fontSize="small" />
  ) : (
    <KeyboardArrowRight fontSize="small" />
  )
}
