import { useCallback } from 'react'

import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import type { UpdateFunction } from '@workwave-tidal/core/hooks/useQueryState'

export type Props = {
  sidebarPosition: 'row' | 'row-reverse'
  setCollapsed: UpdateFunction<boolean>
}

export function NavigationCollapsed(props: Props) {
  const { sidebarPosition, setCollapsed } = props

  // --------------------------------------------
  // Sidebar state

  const handleExpandSidebar = useCallback(() => {
    setCollapsed(false)
  }, [setCollapsed])

  return (
    <Box
      sx={{
        width: 60,
        zIndex: 10,
        position: 'absolute',
        marginTop: '22px',
        background: '#EEEEEE',
      }}
    >
      <Button onClick={handleExpandSidebar} sx={{ color: '#0a7ed3' }}>
        <DoubleArrowIcon
          sx={{
            transform: sidebarPosition === 'row' ? 'scale(1, 1)' : 'scale(-1, 1)',
          }}
        />
      </Button>
    </Box>
  )
}
