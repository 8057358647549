import { MDXProvider as MDX } from '@mdx-js/react'
import type { MDXComponents } from 'mdx/types'
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { Story, type StoryProps } from '@workwave-tidal/stories-helpers/Story'

// ---------------------------------------
// Syntax highlighter
// ---------------------------------------

type CodeProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>

function code({ className, ref, ...props }: CodeProps) {
  const match = /language-(\w+)/.exec(className || '')

  return match ? (
    // @ts-expect-error Some weird inconsistency between type definitions
    <SyntaxHighlighter language={match[1]} PreTag="div" style={ghcolors} wrapLongLines {...props} />
  ) : (
    <code className={className} {...props} />
  )
}

// ---------------------------------------
// Default MDX layout
// ---------------------------------------

interface LayoutProps extends StoryProps {
  components: MDXComponents
}

function Layout(props: LayoutProps) {
  const { components, ...other } = props

  return <Story {...other} />
}

function Hr(props: DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement>) {
  return (
    <hr
      style={{
        width: '100%',
        border: 0,
        borderBottom: 'solid thin #D7D7D7',
        margin: '25px 0px',
      }}
      {...props}
    />
  )
}

// ---------------------------------------

const components: MDXComponents = { code, wrapper: Layout, hr: Hr }

type Props = {
  children: ReactNode
}

export function MDXProvider(props: Props) {
  const { children } = props

  return <MDX components={components}>{children}</MDX>
}
