import { Tooltip, type TooltipProps, styled, tooltipClasses } from '@mui/material'

export const WarnTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.vars.palette.error.dark,
    color: theme.vars.palette.tidalColors.cloudGray[50],
    boxShadow: theme.vars.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.vars.palette.error.dark,
  },
}))
