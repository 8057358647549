import { validateTidalLicense } from '@workwave-tidal/core/base'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Root } from './components/Root.js'

const root = document.getElementById('root')

// TODO: StrictMode should be configurable
if (root) {
  createRoot(root).render(
    <StrictMode>
      <Root />
    </StrictMode>,
  )
}

// #license-validation
validateTidalLicense()
